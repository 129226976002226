import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import { mapGetters } from 'vuex';
import * as assetsApi from '@api/assets';
export default {
  name: 'AssetsCheckIndex',
  mixins: [common],
  computed: {
    ...mapGetters('user', ['userInfo', 'projectInfo'])
  },
  data() {
    return {
      projectId: '',
      projectName: '',
      spaceFullCode: '',
      spaceName: '',
      spaceType: '',
      checkId: '',
      productList: [],
      productList2: [] // 数量异常的资产
    };
  },

  mounted() {
    this.projectId = this.projectInfo.dcProjectId;
    this.projectName = this.projectInfo.projectName;
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    getData() {
      assetsApi.getCheckDetail(this.id).then(res => {
        let item = res.data.data;
        this.spaceName = item.spaceName;
        if (res.data.data.detailList) {
          let detailList = [];
          let detailList2 = [];
          for (let detail of item.detailList) {
            detail.oldCheckNum = detail.checkNum;
            detailList.push(detail);
            if (!detail.isCorrent) {
              detailList2.push(detail);
            }
          }
          this.productList = detailList;
          this.productList2 = detailList2;
        }
      });
    }
  }
};